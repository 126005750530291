<!--
 * @Author: 张阳帅
 * @Date: 2024-09-23 17:52:37
 * @LastEditTime: 2021-03-12 16:46:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\views\appoint\deptList.vue
-->
<template>
  <div class="nav_page">
    <van-tabs v-model="active" swipeable sticky>
      <van-tab v-for="(item, index) in navList" :key="index" :title="item.dhwz">
        <div v-for="(lcItem, lcIndex) in item.lcList" :key="lcIndex" class="page_body">
          <div class="body_item">
            <div class="item_title">
              <van-icon name="location" color="#1e80ff" size="20px" class="title_icon" />
              <div class="title_name">
                {{ lcItem.jtlc }}
              </div>
            </div>
            <div v-for="(dzItem, dzIndex) in lcItem.dzList" :key="dzIndex" class="item_cont">
              <van-tag plain type="primary" class="cont_name">
                {{ dzItem.xxjs }}
              </van-tag>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import {gjcModel} from '../../../api/gjcApi'

export default {
  data(){
    return{
      active: 0,
      navList:[], //导航
      select_active: 0
    }
  },
  mounted() {
    this.getHospNavData()
  },
  methods:{
    //获取院内导航
    getHospNavData(){
        const params = {
            dzmc:''
        }
      gjcModel.getHospNavData(params).then((result) => {
        console.log(result.data)
        this.navList = result.data
      })
    }
  }
}
</script>
<style scoped lang='less'>
.nav_page{
    background-color: #f1f1f1;
}
.page_body{
    margin: 20px 30px;
    padding: 15px;
    background-color: white;
    border-radius: 10px;

    .item_title{
      display: flex;

        .title_icon{
          margin-right: 10px;
        }
        .title_name{
          font-size: 32px;
          line-height: 40px;
        }
    }
    .item_cont{
      display: flex;
      margin-top: 10px;
      .cont_name{
        padding: 10px;
        border-radius: 5px;
      }
    }
}

</style>